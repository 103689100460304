import { triggerCustomGaEvent } from '@helpers/miscelleanous';
import { Image, Text } from '@ui/atoms';
import React, { useCallback, useEffect, useState } from 'react'
const JIRAAF_AI_ID = 'jiraafAi';


const NavItems = ({
    navItems,
    viewMoreLinks,
    setViewMoreLinks,
    redirectTo,
    listedUrlsExceptMoreLinks,
    router,
    setIsBottomNavVisible,
    highlights,
    setHighlights,
    toggleGenAiVisibility,
    genAiVisible
}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => { 
    setIsBottomNavVisible(true) 

    return () => {
         setIsBottomNavVisible(false)
    }
  }, []);

  const handleClick = (item, index) => {
    setHighlights(item?.id);
    if(item?.gTag) triggerCustomGaEvent(item?.gTag);
    if(item.id === JIRAAF_AI_ID){
        toggleGenAiVisibility();
        if(genAiVisible){
            updateBottomNavIndex(router.pathname)
        }else{
            updateBottomNavIndex('', { id: JIRAAF_AI_ID });
        }
    }else if(item?.redirect){
        redirectTo(item.redirect);
        updateBottomNavIndex(item.redirect);
    }else{
        setViewMoreLinks({ links: item.moreLinks, show: !(viewMoreLinks.show) })
        updateBottomNavIndex('', { id: "more" });
    }

    if(item?.id !== JIRAAF_AI_ID && genAiVisible){
      toggleGenAiVisibility();
    }
  }

  const getImageSrc = useCallback((item, highlights, listedUrlsExceptMoreLinks, routerPathname) => {
    const isRedirectMatch = item?.redirect === routerPathname;
    const isMoreLink = !listedUrlsExceptMoreLinks.includes(routerPathname) && item?.id === 'more';
  
    return ((highlights === item?.id) || isRedirectMatch || isMoreLink)
      ? item.activeImage
      : item.image;
  }, []);

  const getTextClassNames = useCallback((item, highlights, routerPathname) => {
    const isRedirectMatch = item?.redirect === routerPathname;
    const isMoreLink = !listedUrlsExceptMoreLinks.includes(routerPathname) && item?.id === 'more';
    const textColor = (((highlights === item?.id) || isRedirectMatch) || isMoreLink) ? 'text-primary-500' : 'text-gray-500';
  
    return `p7-medium text-center ${textColor}`;
  }, []);

  function updateBottomNavIndex(path, options){
      const activeIndex = options ? navItems.findIndex((item) => item?.id === options.id) : navItems.findIndex((item) => item?.redirect === path);

      if(activeIndex >= 0) setActiveIndex(activeIndex)
      else setActiveIndex(navItems.length - 1);
  }

  useEffect(() => {
      if(router?.pathname && navItems) updateBottomNavIndex(router.pathname);

      if(genAiVisible) toggleGenAiVisibility();
  }, [navItems]);

  return (
      <div
          className={`bg-basicWhite fixed bottom-0 w-full px-2 xl:hidden shadow-bottom-sheet`}
          style={{
              zIndex: 99,
          }}
      >
        <div className='px-2 w-full relative'>
          <div
              className="absolute top-0 left-0 h-[2px] bg-secondary-300 transition-transform duration-300 z-50"
              style={{
                  width: `${100 / navItems.length}%`,
                  transform: `translateX(${activeIndex * 100}%)`,
              }}
          ></div>
        </div>

          <div className={`grid-cols-${navItems.length} grid gap-1 w-full`}>
            {navItems.map((item, index) => (
                <div
                    key={index}
                    className="flex justify-center items-center flex-col gap-2 bg-basicWhite/80 relative z-10 py-3 cursor-pointer"
                    onClick={() => handleClick(item, index)}
                >
                    <div className="relative">
                        <Image
                            src={getImageSrc(
                                item,
                                highlights,
                                listedUrlsExceptMoreLinks,
                                router.pathname
                            )}
                            width={24}
                            height={24}
                        />
                    </div>
                    <Text
                        content={item.label}
                        className={getTextClassNames(
                            item,
                            highlights,
                            router.pathname
                        )}
                    />
                </div>
            ))}
          </div>
      </div>
  );
}

export default NavItems;