import { OPPORTUNITY_BOND_TYPES } from '@components/OpportunityListing/utils/constants';

const { default: routes } = require('@helpers/routes');

export const handleOpportunitiesNavigation = (isAuthorized, router) => {
    if (isAuthorized) {
        router.push(routes.opportunities);
    } else {
        router.push(routes.loggedOutOpportunities);
    }
};

export const handleProductsOppNavigation = (isAuthorized, router, slug) => {
    let queryParam;
    switch (slug) {
        case 'treasury-bills':
            queryParam = `?type=${OPPORTUNITY_BOND_TYPES.TreasuryBill}`;
            break;
        case 'sdi':
            queryParam = `?type=${OPPORTUNITY_BOND_TYPES.SDIS}&regulation=rbi`;
            break;
        case 'corporate-bond':
        case 'sovereign-gold-bonds':
        case 'g-secs-sdls':
        default:
            queryParam = '';
            break;
    }
    if (isAuthorized) {
        return router.push(`/opportunities${queryParam}`);
    }
    return router.push(`/active/opportunities${queryParam}`);
};
