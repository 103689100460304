export const OPPORTUNITY_BOND_TYPES = Object.freeze({
    BOND: 'bond',
    SDIS: 'SecuritisedDebtInstrument',
    UNLISTEDSDI: 'UnlistedSecuritisedDebtInstrument',
    TreasuryBill: 'TreasuryBill',
});

export const OPPORTUNITY_CARD_TYPES = Object.freeze({
    ACTIVE: 'active',
    CLOSED: 'closed',
    FESTIVE_DAYS: 'festiveDays',
    ADMIN_DEAL: 'adminDeal',
    COMING_SOON: 'comingSoon',
});

export const OPPORTUNITY_STATUS = Object.freeze({
    OPEN: 'open',
    CLOSED: 'closed',
});

export const OPPORTUNITY_FILTERS = Object.freeze({
    TYPE: 'type',
    STATUS: 'status',
    PAGE: 'page',
    LIMIT: 'limit',
});

export const OPPORTUNITY_TAGS = Object.freeze({
    NEWLY_ADDED: 'newlyAdded',
    HIGH_YIELD: 'highYield',
    MIN_INVESTMENT: 'minInvestment',
    SHORT_TENURE: 'shortTenure',
    CURATED: 'curated',
});

export const SORT_OPTIONS = [
    { label: 'Default', value: 'default' },
    { label: 'Yield: High to low', value: 'yield_desc' },
    { label: 'Tenure: Short to long', value: 'tenure_asc' },
    { label: 'Tenure: Long to short', value: 'tenure_desc' },
    { label: 'Rating: High to low', value: 'rating_desc' },
    { label: 'Min Investment: High to low', value: 'amount_desc' },
    { label: 'Min Investment: Low to high', value: 'amount_asc' },
];

export const CONSTANTS = {
    MIN_MAX_AMOUNT: [0, 125000],
    MIN_INVESTMENT: 0,
    MAX_INVESTMENT: 125000,
    STEP: 1000,
    MIN_STEP_BTW_THUMBS: 10,
};

export const FILTER_OPTIONS = {
    yield: [
        { label: 'Upto 8%', value: '<8' },
        { label: '8-10%', value: '8-10' },
        { label: '10-12%', value: '10-12' },
        { label: '>12%', value: '>12' },
    ],
    tenure: [
        { label: 'Upto 1Y', value: '<1' },
        { label: '1Y-3Y', value: '1-3' },
        { label: '>3Y', value: '>3' },
    ],
    rating: [
        { label: 'High', value: 'high' },
        { label: 'Medium', value: 'medium' },
        { label: 'Low', value: 'low' },
    ],
};

export const opportunitiesFilterDefaultValues = {
    yield: [],
    tenure: [],
    amount: CONSTANTS.MIN_MAX_AMOUNT,
    rating: [],
    secured: false,
};

export const customMinInvestmentSteps = [
    0, 10000, 25000, 50000, 75000, 100000, 125000,
];

export const DESKTOP_LIMIT = 9;

export const MOBILE_LIMIT = 8;

export const DEFAULT_DATA = {
    count: 0,
    rows: [],
};

export const oppListingFilterKeys = [
    'yield',
    'tenure',
    'secured',
    'amount',
    'rating',
];
