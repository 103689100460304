import React, { useState } from 'react';
import texts from '@molecules/BottomNavigation/en.json';
import { useRouter } from 'next/router';
import { CollapsibleBar } from '@molecules/BottomNavigation/CollapsibleBar';
import 'animate.css/animate.min.css';
import RmElement from '@molecules/BottomNavigation/RmElement';
import useBottomNavigation from '@hooks/useBottomNavigation';
import { getAuth } from '@services/identity.service';
import NavItems from '@molecules/BottomNavigation/NavItems';
import { navigationGaEventsName } from '@helpers/ga-events-name-enum';
import useAIChatBotStore from '@store/useAIChatBotStore';
import useAuthStore from '@store/useAuthStore';

const BottomNavigation = ({ investor, rmDetails, visible = null, setIsBottomNavVisible, showRMDetails }) => {
  const router = useRouter();
  const auth = getAuth();
  const isVisible = visible ?? (texts?.firstLevelPagesWithAuth.indexOf(router?.pathname) !== -1);
  const { canUseAiBot } = useAuthStore();
  const notShowBottomNavigation = !isVisible || !auth;
  const [viewMoreLinks, setViewMoreLinks] = useState({ links: [], show: false });
  const navigationLinks = texts?.navigationLinks?.filter(link =>
    canUseAiBot ? link.version.includes("new") : link.version.includes("old")
  );
  const navItems = useBottomNavigation({auth, investor, defaultNavItems: navigationLinks, rmDetails, showRMDetails});
  const { isVisible: genAiVisible, toggleVisibility: toggleGenAiVisibility } = useAIChatBotStore((state) => ({
    toggleVisibility: state.toggleVisibility,
    isVisible: state.isVisible
  }));
  const isAppView = router?.query.is_app == 'true';
  const listedUrlsExceptMoreLinks = navigationLinks.reduce((acc, curr) => {
    acc.push(curr?.redirect)
    return acc;
  }, []);
  const [highlights, setHighlights] = useState('');  
  const redirectTo = (url) => {
    router.push(url);
    setViewMoreLinks([]);
  };

  const collapsibleElements = (id) => {
      const elements = {
        "relationship-manager": <RmElement rmDetails={rmDetails} gTag={navigationGaEventsName.CLICKED_CHAT_WITH_RM_MOBILE_WEB_NAVIGATION} />
      }

      return elements[id];
  };

  if(notShowBottomNavigation || isAppView) return;
  
  return (
      <div className="xl:hidden absolute">
          <NavItems
              {...{
                  navItems,
                  viewMoreLinks,
                  setViewMoreLinks,
                  redirectTo,
                  router,
                  listedUrlsExceptMoreLinks,
                  setIsBottomNavVisible,
                  highlights,
                  setHighlights,
                  toggleGenAiVisibility,
                  genAiVisible
              }}
          />
          <CollapsibleBar
              list={viewMoreLinks.links}
              visible={viewMoreLinks.show}
              redirectTo={redirectTo}
              setVisible={(val) => {
                if(!val) setHighlights('');
                setViewMoreLinks(val ? { links: viewMoreLinks, show: true } : {...viewMoreLinks, show: false})
              }}
              collapsibleElements={collapsibleElements}
              router={router}
          />
      </div>
  );
};

export default BottomNavigation;